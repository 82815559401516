import React from "react"
import { Link } from "gatsby"
import { Row, Col, Card } from "react-bootstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { FormEventAnalytics } from "../../utils/analytics"
import { setUTMSource } from "../../utils/storage"
import scrollTo from "gatsby-plugin-smoothscroll"

class OnlineLive extends React.Component {
  componentDidMount() {
    setUTMSource()
  }

  render() {
    return (
      <Layout>
        <SEO
          title={"Online Live"}
          titleDesc={
            "Fuse Classroom provides online classes through Zoom integrated platform equipped with the best tools to deliver effective online learning experience."
          }
          titleKeywords={
            "online classes, zoom online classes, zoom class, zoom classroom, online classrooms, zoom online classroom, live class, live online classes, live classes"
          }
          fbDes={"Zoom integrated platform equipped with best tools to run online classes more conveniently."}
          twitDesc={"Zoom integrated platform equipped with best tools to run online classes more conveniently."}
          seoLink={"platform/online-live/"}
        />

        {/* top section */}
        <section role="top--section" className="bg-light-green overflow-hidden">
          {/* content */}
          <article className="container">
            <Row className="align-items-center py-4">
              <Col md="6" className="pt-md-5 pb-5">
                <div className="text-green">
                  <h1>Online Live</h1>
                  <div className="h4 mt-4 text-black font-weight-normal">
                    Offer live online classes in a matter of minutes with our Zoom integrated platform equipped with the
                    best tools instructors need.
                  </div>

                  <div className="main-cta mt-4">
                    <Link
                      to="/request-for-more-info/"
                      className="btn btn-success mr-3 btn-lg font-weight-semibold"
                      // onClick={() => FormEventAnalytics("OnlineLive_Top_Request_Demo")}
                      state={{ page: "OnlineLive" }}
                      id="request--for-more-info__top"
                    >
                      REQUEST FOR MORE INFO
                    </Link>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="fluid-left d-none d-md-block mt-3 mb-3">
                  <img src="/images/platform/onlinelive/online-live.webp" alt="Online Live" width="640" height="auto" />
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* top section end */}

        {/* Online Live */}
        <section className="pb-5 d-none d-md-block" id="ai-info">
          <article className="container ai-features sm-2">
            <Row>
              <Col sm="12" className="py-4 mb-5 text-center">
                <div className="text-center h1">
                  Online
                  <br />
                  Live
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Audio-Video-Streaming-Platform")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/av-streaming.svg"
                          alt="Video and audio streaming"
                          width="45"
                          height="auto"
                        />
                      </div>
                      <Card.Title className="ai-title">Video and audio streaming </Card.Title>
                      <div className="small">Host and record live online classes.</div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#White-Boards")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/whiteboard.svg" alt="White Boards" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">White Boards</Card.Title>
                      <div className="small">Demonstrate concepts by drawing diagrams, graphs, and equations.</div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Breakout-Rooms")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/breakout-rooms.svg"
                          alt="Breakout Rooms"
                          className="img-fluid"
                          width="45"
                          height="auto"
                        />
                      </div>
                      <Card.Title className="ai-title">Breakout Rooms</Card.Title>
                      <div className="small">
                        Facilitate group discussions and 1-on-1 tutoring sessions through the online live feature.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Scheduling")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/scheduling.svg"
                          alt="Scheduling"
                          className="img-fluid"
                          width="45"
                          height="auto"
                        />
                      </div>
                      <Card.Title className="ai-title">Scheduling</Card.Title>
                      <div className="small">Automated schedule lets you know when online classes are happening.</div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>
            </Row>
          </article>
        </section>
        {/* Online Live End */}

        {/* Audio and Video Streaming Platform */}
        <section id="Audio-Video-Streaming-Platform" className="py-5">
          <article className="container py-2">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/platform/onlinelive/av-streaming.webp"
                  alt="Video and Audio Streaming"
                  className="img-fluid"
                  width="630"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/av-streaming.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Video and Audio Streaming</h2>
                  <div className="h5 text-black font-weight-normal">
                    Instructors can easily host and record live classes directly within Fuse Classroom. Participants can
                    stream their own video and audio.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* fuse classroom Online Live end */}

        {/* White Boards */}
        <section id="White-Boards" className="py-5 bg-80 left">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img
                  src="/images/platform/onlinelive/whiteboard.webp"
                  alt="White Boards"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/whiteboard.svg" alt="White Boards" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">White Boards</h2>
                  <div className="h5 text-black font-weight-normal">
                    Instructors can use a white board tool during class to draw diagrams, equations, and graphs to
                    illustrate concepts as though they never left the in-person classroom.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* White Boards end */}

        {/* Breakout Rooms */}
        <section id="Breakout-Rooms" className="py-5">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/platform/onlinelive/breakout-rooms.webp"
                  alt="Breakout Rooms"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/breakout-rooms.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Breakout Rooms</h2>
                  <div className="h5 text-black font-weight-normal">
                    The Breakout Rooms feature allows instructors to facilitate group discussions and 1-on-1 tutoring.
                    Multiple breakout rooms can be used for group projects too.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Breakout Rooms end) */}

        {/* Scheduling */}
        <section className="py-5 bg-80 left" id="Scheduling">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img
                  src="/images/platform/onlinelive/scheduler.webp"
                  alt="Scheduling"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/scheduling.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Scheduling</h2>
                  <div className="h5 text-black font-weight-normal">
                    To keep students on time, the automated scheduler adds class information to your calendar when a
                    class slot is assigned. Automatic rescheduling announcements are sent out in the event of conflict.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Scheduling end */}

        {/* call to action */}
        <section className="py-5">
          <article className="container">
            <Row className="justify-content-center">
              <Col lg="10">
                <div className="rounded border border-success p-4 text-center">
                  <span className="d-inline-block mr-md-5 mb-0 h4 font-weight-bold">
                    Interested in more information about our platform?
                  </span>
                  {/* <button className="btn btn-success" onClick={() => this.setState({modalShow: true})}>Request a Demo</button> */}
                  <Link
                    to="/request-for-more-info/"
                    className="btn btn-success mt-3 mt-xl-0"
                    // onClick={() => FormEventAnalytics("OnlineLive_Bottom_Request_Demo")}
                    state={{ page: "OnlineLive" }}
                    id="request--for-more-info__bottom"
                  >
                    REQUEST FOR MORE INFO
                  </Link>
                </div>
              </Col>
            </Row>
          </article>
        </section>
      </Layout>
    )
  }
}

export default OnlineLive
